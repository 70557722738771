<template>
  <v-row align="center">
    <v-col cols="12">
      <v-col class="">
        <inline-svg
          class="mt-6"
          :max-height="fullPageError ? 400 : 65"
          :src="icon || '/media/placeholders/all_done.svg'"
        />
        <p
          v-html="firstText || 'No data'"
          class="mt-4 empty-title dark-grey-text bold-text text-center"
        ></p>
        <p class="mt-4 mb-5 bodyFont font-weight-normal text-muted text-center">
          {{ secondText }}
        </p>
      </v-col>
    </v-col>
    <v-col v-if="withButton" cols="12">
      <div class="d-flex align-center justify-center">
        <v-btn
          class="main primary white--text"
          @click="$router.push({ name: 'dashboard' })"
        >
          <v-icon left small>mdi-arrow-left</v-icon>
          Go to Dashboard
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    firstText: {
      type: String,
      default: null,
    },
    secondText: {
      type: String,
      default: null,
    },
    fullPageError: {
      type: String,
      default: null,
    },
    withButton: {
      type: Boolean,
      default: false,
    },
    permissionIssues: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss"></style>
